/* eslint-disable no-throw-literal */

import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";
import IBanner from "./interface/IBanner";

const PostBanner = async ({
  id,
  type,
  formData,
}: {
  type: "update" | "create";
  formData: FormData;
  id?: number;
}): Promise<IBanner> => {
  const URL = queryFormatter({
    route:
      type === "create"
        ? "dashboard/stations/banners"
        : `dashboard/stations/banners/${id}`,
    querys: {},
  });
  try {
    const { data }: AxiosResponse<IBanner> =
      type === "create"
        ? await axiosProvider.post(URL, formData)
        : await axiosProvider.put(URL, formData);
    return data;
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default PostBanner;
