/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import EditImage from "../../../../../components/Exotics/EditImage";
import useVerifyObjectEqual from "../../../../../hooks/useVerifyObjectEqual";
import Select from "../../../../../components/Select";
import IBanner from "../../../../../services/api/customer/interface/IBanner";
import { useMutation } from "@tanstack/react-query";
import CustomerService from "../../../../../services/api/customer/CustomerService";
import { toast } from "react-toastify";
import Column from "../../../../../components/containers/Column";
import Input from "../../../../../components/Input";
import verifyImageSize from "../../../../../helpers/images/verifyImageSize";
import { MocksBannersTypes } from "../BannersInformationIsp/mocks";

interface IImageItem {
  id?: number;
  image: string;
  href?: string;
  type?: string;
}

type IImagesByType = Record<string, IImageItem[]>;

const BannersInformationHome = ({
  data,
  id,
}: {
  data: IBanner[];
  id: number;
}) => {
  const [imagesByType, setImagesByType] = useState<IImagesByType>({
    "1": [],
    "2": [],
    "3": [],
  });

  const [initialImages, setInitialImages] = useState<IImagesByType>({
    "1": [],
    "2": [],
    "3": [],
  });

  const [type, setType] = useState<{ name: string; id: string } | undefined>({
    id: "1",
    name: "Destaque",
  });

  const disabledButton = useVerifyObjectEqual({
    initialObject: initialImages[type?.id || "1"],
    actualObject: imagesByType[type?.id || "1"],
  });

  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    mutate,
    isPending,
    data: newBanner,
  } = useMutation({
    mutationKey: ["update-banners-home"],
    mutationFn: ({
      id,
      type,
      formData,
    }: {
      id?: number;
      type: "update" | "create";
      formData: FormData;
    }) => CustomerService.postBanner({ id, type, formData }),
    onSuccess: () => {
      toast.success("Banner criado/alterado com sucesso");
    },
  });

  const { mutate: deleteBanner } = useMutation({
    mutationKey: ["delete-banners-home"],
    mutationFn: (id: number) => CustomerService.removeBanners(id),
    onSuccess: () => {
      toast.success("Banner deletado com sucesso");
    },
  });

  const createFormData = useCallback(
    ({
      image,
      typeFormData,
      href,
    }: {
      image: Blob;
      typeFormData: string;
      href: string;
    }) => {
      const formData = new FormData();

      if (id) {
        formData.append("companyId", id.toString());
        formData.append("title", "novo banner");
        formData.append("type", typeFormData);
        formData.append("file", image);
        formData.append(
          "orderBy",
          imagesByType[type?.id as string].length.toString()
        );
        formData.append("position", type?.id as string);
        formData.append("href", href);
        formData.append("size", "rectangle");
        return formData;
      }
      return formData;
    },
    [type?.id, id, imagesByType]
  );

  const handleImageChange = async ({
    blob,
    index,
  }: {
    blob: Blob;
    index: number;
  }) => {
    const typeKey = type?.id || "1";

    // Se o `type.id` for "1" || "2", realiza a validação do tamanho
    if (typeKey === "1" || typeKey === "2") {
      const imageUrl = URL.createObjectURL(blob);
      const isValid = await verifyImageSize(imageUrl, 764, 236);
      URL.revokeObjectURL(imageUrl);

      if (!isValid) {
        toast.error("A imagem deve ter 764x236");
        return;
      }
    }

    setCurrentIndex(index);
    const updatedImage = URL.createObjectURL(blob);

    setImagesByType((prevImages) => {
      const currentImages = [...(prevImages[typeKey] || [])];

      if (currentImages[index]?.id) {
        // Atualizar imagem existente
        currentImages[index].image = updatedImage;
      } else {
        // Criar nova imagem
        currentImages[index] = { ...currentImages[index], image: updatedImage };
      }

      return {
        ...prevImages,
        [typeKey]: currentImages,
      };
    });

    const formData = createFormData({
      image: blob,
      typeFormData: imagesByType[typeKey][index]?.type || "",
      href: imagesByType[typeKey][index]?.href || "",
    });

    if (imagesByType[typeKey][index]?.id) {
      mutate({
        type: "update",
        formData,
        id: imagesByType[typeKey][index]?.id,
      });
    } else {
      mutate({ formData, type: "create" });
    }
  };

  const init = () => {
    const defaultImages: IImagesByType = {
      "1": [],
      "2": [],
      "3": [],
    };
    if (!data || data.length === 0) {
      setImagesByType(defaultImages);
      setInitialImages(defaultImages);
      setType({ id: "1", name: "Destaque" });
      return;
    }

    const newImagesByType = data.reduce(
      (acc, banner) => {
        const typeId =
          banner.position === 1 ? "3" : banner.position === 2 ? "2" : "3";
        acc[typeId].push({
          id: banner.id,
          image: banner.image,
          href: banner.href,
          type: banner.type,
        });
        return acc;
      },
      { ...defaultImages }
    );

    setImagesByType(newImagesByType);
    setInitialImages(newImagesByType);

    const initialType =
      data[0]?.position === 1
        ? { id: "3", name: "Carrousel" }
        : data[0]?.position === 2
        ? { id: "2", name: "Vertical" }
        : { id: "1", name: "Destaque" };

    setType(initialType);
  };

  useEffect(() => {
    init();
  }, [data]);

  useEffect(() => {
    if (newBanner) {
      const updateImage = imagesByType[type?.id || "1"];
      updateImage[currentIndex].id = newBanner.id;
      setImagesByType((prevImages) => ({
        ...prevImages,
        [type?.id || "1"]: updateImage,
      }));
    }
  }, [newBanner]);

  const filteredImages: IImageItem[] = useMemo(() => {
    const images = imagesByType[type?.id || "1"] || [];
    if (type?.id === "3" || type?.id === "2") {
      return [
        ...images,
        ...Array(Math.max(0, 3 - images.length)).fill({
          image: "Não há imagem no momento",
        }),
      ];
    }
    return images.length > 0 ? images : [{ image: "Não há imagem no momento" }];
  }, [imagesByType, type]);

  return (
    <SubmitEditsForm
      title="Banners Home"
      buttonProps={{ disabled: disabledButton }}
      noRenderButton
      renderInsideComponents={() => (
        <>
          <Select
            value={type}
            placeholder="Selecione o tipo"
            onChange={(value: any) => setType(value)}
            getOptionLabel={(option: any) => option.name}
            getOptionValue={(option: any) => option.id}
            data={[
              { id: "3", name: "Carrousel" },
              { id: "1", name: "Destaque" },
              { id: "2", name: "Vertical" },
            ]}
          />

          {filteredImages.map((img, index) => (
            <EditImage
              key={`list-image-${img?.id || index}-${index}`}
              title={""}
              width={"90%"}
              onChange={(blob) => handleImageChange({ blob, index })}
              buttonProps={{
                text: "Salvar",
                disabled: !(img.href && img.type),
              }}
              buttonPropsSecondary={{
                text: "Excluir",
                onClick: () => {
                  setImagesByType((prevImages) => {
                    const newImages = { ...prevImages };
                    newImages[type?.id || "1"] = newImages[
                      type?.id || "1"
                    ].filter((_img, i) => i !== index);
                    return newImages;
                  });
                  img.id && deleteBanner(img?.id);
                },
                disabled: !img.id,
              }}
              image={
                img?.image === "Não há imagem no momento"
                  ? undefined
                  : img.image
              }
              renderInsideComponents={() => (
                <Column alignItems="center" margin={"10px 0px 0px"}>
                  <div style={{ width: "90%" }}>
                    <Select
                      width="100%"
                      value={MocksBannersTypes.find(
                        (type) => type.id === img?.type
                      )}
                      placeholder="Selecione o tipo de açao"
                      data={MocksBannersTypes}
                      getOptionLabel={(option: any) => option.name}
                      getOptionValue={(option: any) => option.id}
                      onChange={(value: any) => {
                        setImagesByType((prevImages) => {
                          const newImages = { ...prevImages };
                          newImages[type?.id || "1"][index] = {
                            ...newImages[type?.id || "1"][index],
                            type: value.id,
                          };
                          return newImages;
                        });
                      }}
                    />
                  </div>
                  <Input
                    margin={"10px 0px 0px"}
                    type="text"
                    value={img?.href}
                    onChange={({ target: { value } }) => {
                      setImagesByType((prevImages) => {
                        const newImages = { ...prevImages };
                        newImages[type?.id || "1"][index] = {
                          ...newImages[type?.id || "1"][index],
                          href: value,
                        };
                        return newImages;
                      });
                    }}
                    width={"90%"}
                    placeholder="Link externo ou interno"
                  />
                </Column>
              )}
            />
          ))}
        </>
      )}
    />
  );
};

export default BannersInformationHome;
