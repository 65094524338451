import SubmitEditsForm from "../../../../../components/forms/SubmitEditsForm";
import Input from "../../../../../components/Input";
import { ICustomer } from "../../../../../interfaces/customer/ICustomer";

export default function UtilsLinks({ basic }: { basic: ICustomer }) {
  return (
    <SubmitEditsForm
      title="Links Úteis"
      noRenderButton
      renderInsideComponents={() => (
        <>
          <Input
            name="link1"
            label="App Web"
            value={"https://app.stations.cloud?company=" + basic.id}
            disabled
            width={"300px"}
          />
        </>
      )}
    />
  );
}
