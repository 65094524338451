import Button from "../../buttons/Button";
import GenericText from "../../texts/GenericText";
import { ISubmitEditsFormProps } from "./interfaces/ISubmitEditsFormProps";
import { SEFContainer } from "./styles";

export default function SubmitEditsForm(props: ISubmitEditsFormProps) {
  const { buttonProps = {}, noRenderButton = false } = props;
  return (
    <SEFContainer>
      <GenericText margin={"0px 0px 20px 0px"}>{props.title}</GenericText>
      {props.renderInsideComponents()}
     {!noRenderButton && <Button
        text={"Salvar"}
        width={"100px"}
        height={"35px"}
        alignSelf="flex-end"
        margin={"25px 0px 0px 0px"}
        {...buttonProps}
      ></Button>}
    </SEFContainer>
  );
}
