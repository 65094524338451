/**
 * Verifica se as dimensões de uma imagem são compatíveis com os valores esperados.
 * @param imageUrl - URL ou caminho da imagem.
 * @param targetWidth - Largura esperada (padrão: 245).
 * @param targetHeight - Altura esperada (padrão: 112).
 * @returns Promise<boolean> - Retorna true se as dimensões forem válidas, false caso contrário.
 */
const verifyImageSize = (
  imageUrl: string,
  targetWidth: number = 764,
  targetHeight: number = 236
): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => {
      resolve(img.width === targetWidth && img.height === targetHeight);
    };

    img.onerror = () => {
      resolve(false); // Retorna false se houver erro ao carregar a imagem.
    };
  });
};

export default verifyImageSize;
