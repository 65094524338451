/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import { useNavigate, useParams } from "react-router-dom";
import EntityHeader from "../../../components/headers/EntityHeader/index.tsx";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomerService from "../../../services/api/customer/CustomerService.ts";
import LoaderGlobal from "../../../components/Exotics/loaders/LoaderGlobal/index.tsx";
import Card from "../../../components/Card/index.tsx";
import Row from "../../../components/containers/Row/index.tsx";
import { Seprator } from "../../../components/Exotics/Seprator/index.tsx";
import Column from "../../../components/containers/Column/index.tsx";
import GenericText from "../../../components/texts/GenericText/index.tsx";
import { CSCContainer } from "../../../components/containers/ContentSpacingContainer/styles.ts";
import BasicInformation from "./DetailsBoxes/BasicInformation/index.tsx";
import FinancialInformation from "./DetailsBoxes/FinancialInformation/index.tsx";
import ImagesInformation from "./DetailsBoxes/ImagesInformation/index.tsx";
import MkInformation from "./DetailsBoxes/MkInformation/index.tsx";
import ProductsInformation from "./DetailsBoxes/ProductsInformation";
import ExcelContainer from "./DetailsBoxes/ExcelContainer/index.tsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import Modal from "../../../components/Modal/index.tsx";
import EditStatus from "../../../components/EditStatus/EditStatus.tsx";
import { toast } from "react-toastify";
import BasicIntegration from "./DetailsBoxes/BasicIntegration/index.tsx";
import Button from "../../../components/buttons/Button/index.tsx";
import AccessBox from "./DetailsBoxes/AccessBox/index.tsx";
import MvnoTip from "./DetailsBoxes/MvnoTip/index.tsx";
import integrationService from "../../../services/api/integration/integrationService.ts";
import { IGetIntegration } from "../../../services/api/integration/interface/index.ts";
import BannersInformationHome from "./DetailsBoxes/BannersInformationHome/index.tsx";
import BannersInformationIsp from "./DetailsBoxes/BannersInformationIsp/index.tsx";
import UtilsLinks from "./DetailsBoxes/UtilsLinks/index.tsx";

export default function Details() {
  let { id } = useParams();
  const navigate = useNavigate();
  if (!id) {
    navigate("/");
    return <></>;
  }
  const [selectStatus, setSelectStatus] = useState<{
    id?: number;
    name?: string;
    value?: string;
  }>({});
  const [reason, setReason] = useState("");
  const [open, setOpen] = useState(false);

  const { data, isLoading } = useQuery({
    queryKey: ["customer-details", { id }],
    queryFn: () => CustomerService.details({ id }),
  });

  const { data: dataIntegration, isLoading: isLoadingIntegration } = useQuery({
    queryKey: ["get-integration", data?.id],
    queryFn: () => integrationService.getIntegration(data?.id as number),
    retry: false,
    enabled: !!data?.id,
  });

  const { mutate } = useMutation({
    mutationKey: ["customer-update-image"],
    mutationFn: CustomerService.updateImages,
  });

  const {
    mutate: updateStatus,
    isPending,
    isSuccess,
  } = useMutation({
    mutationKey: ["update-customer-status"],
    mutationFn: CustomerService.updateStatus,
    onSuccess: () => handleCloseModal(),
  });

  const { data: banners, isLoading: isLoadingBanners } = useQuery({
    queryKey: ["get-banners", id],
    queryFn: () => CustomerService.getBanners(Number(id)),
    select: (data) => data.filter((banner) => banner.productId !== 11),
  });

  const { data: bannersIsp, isLoading: isLoadingBannersIsp } = useQuery({
    queryKey: ["get-banners-isp", id],
    queryFn: () => CustomerService.getBanners(Number(id), 11),
  });

  const init = () => {
    if (data?.active === 1) {
      setSelectStatus({
        name: "ativo",
        value: "active",
        id: 1,
      });
    } else if (data?.active === 0) {
      setSelectStatus({
        name: "inativo",
        value: "disable",
        id: 0,
      });
    } else {
      setSelectStatus({
        name: "Suspenso",
        value: "suspended",
        id: 2,
      });
    }
  };

  const disableButton = useMemo(() => {
    if (!reason.length) return true;
    const verifyStatus =
      data?.active === 1
        ? { name: "ativo", value: "active", id: 1 }
        : data?.active === 0
        ? { name: "inativo", value: "disable", id: 0 }
        : { name: "Suspenso", value: "suspended", id: 2 };
    return verifyStatus.id === selectStatus.id;
  }, [reason.length, selectStatus?.id, data?.active]);

  const handleCloseModal = () => {
    setReason("");
    setOpen(!open);
  };

  const handleConfirm = useCallback(() => {
    if (data) {
      const body = {
        action: selectStatus?.value || "",
        comments: reason || "",
      };
      updateStatus({
        id: data.id || 0,
        body,
      });
      data.active = selectStatus?.id ?? 0;
    }
  }, [data, reason, selectStatus?.id, selectStatus?.value]);

  useEffect(() => {
    data && init();
  }, [data]);

  useEffect(() => {
    if (isSuccess) {
      toast.success("Cliente editado com sucesso!👌");
    }
  }, [isSuccess]);

  return (
    <>
      {data?.id && (
        <EntityHeader
          title={"Detalhes do cliente"}
          cnpj={data?.cnpj || ""}
          name={data?.name || ""}
          names={[]}
          brand={data?.companiesImage.image || ""}
          id={data?.id || ""}
          status={data.active ? "active" : "inactive"}
          sendChangeImage={(blob) => {
            const form = new FormData();
            form.append("file", blob);
            mutate({ form, id: id || "", type: "brand" });
          }}
          statusButtonProps={{
            onClick: () => setOpen(!open),
          }}
        />
      )}

      <Row justifyContent="space-around">
        <Button
          width={"300px"}
          text="Ver Planos"
          onClick={() => navigate(`/marketing/planos/${id}`)}
          height={"40px"}
        />
        <Button
          width={"300px"}
          text="Cadastrar associados"
          onClick={() => navigate(`/associados/cadastro/${id}`)}
          height={"40px"}
        />
        <Row width={"300px"} />
      </Row>
      <Seprator margin={"20px 0px"} />
      {data?.id && (
        <Row justifyContent="space-around">
          <Card
            title={String(data.companiesDatum.activeUsers)}
            subTitle="Associados ativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/associados/home/${id}/ativos`),
            }}
          />
          <Card
            title={String(data.companiesDatum.inactiveUsers)}
            subTitle="Associados inativos"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/associados/home/${id}/inativos`),
            }}
          />
          <Card
            title={String(data.companiesDatum.superAppUsers)}
            subTitle="Usuários App"
            backgroundColor="white"
            buttonProps={{
              onClick: () => navigate(`/usuarios/lista/${id}`),
            }}
          />
        </Row>
      )}
      <Seprator margin={"20px 0px"} />
      <Column>
        <GenericText
          size={"h4"}
          font="Bold"
          margin={"15px"}
          alignSelf="flex-start"
        >
          Dados do cliente
        </GenericText>
        <CSCContainer>
          {data?.id && <BasicInformation basic={data} />}
          {data?.id && <FinancialInformation id={data.id} />}
          {data?.id && <ProductsInformation basic={data} />}
          {data?.id && <ImagesInformation basic={data} />}
          {data?.id && <MkInformation basic={data} />}
          {data?.id && (
            <BasicIntegration
              id={data.id}
              basic={data}
              data={dataIntegration as IGetIntegration}
            />
          )}
          {data?.id && <ExcelContainer basic={data} />}
          {data?.id && <AccessBox basic={data} />}
          {data?.id && <MvnoTip basic={data} />}
          {data?.id && banners && (
            <BannersInformationHome data={banners} id={data.id} />
          )}
          {data?.id && bannersIsp && (
            <BannersInformationIsp data={bannersIsp} id={data.id} />
          )}
          {data?.id && <UtilsLinks basic={data} />}
        </CSCContainer>
      </Column>
      <Modal open={open} onClose={() => handleCloseModal()}>
        <EditStatus
          width="500px"
          title="Modificar status da empresa"
          subTitle="Motivo"
          confirmButtonProps={{
            disabled: disableButton,
            loading: isPending,
            onClick: handleConfirm,
          }}
          selectProps={{
            value: selectStatus,
            placeholder: "Selecione um status",
            data: [
              {
                name: "Inativar",
                value: "disable",
                id: 0,
              },
              {
                name: "Ativar",
                value: "active",
                id: 1,
              },
              {
                name: "Suspender",
                value: "suspended",
                id: 2,
              },
            ],
            getOptionLabel: (option: any) => option.name,
            getOptionValue: (option: any) => option.id,
            onChange: (e: any) => setSelectStatus(e),
          }}
          textAreaProps={{
            value: reason,
            onChange: ({ target: { value } }) => setReason(value),
          }}
        />
      </Modal>
      {(isLoading ||
        isLoadingIntegration ||
        isLoadingBanners ||
        isLoadingBannersIsp) && <LoaderGlobal />}
    </>
  );
}
