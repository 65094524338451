/* eslint-disable import/no-anonymous-default-export */
import CreateCustomer from "./Create";
import FCreateAccess from "./FCreateAccess";
import FDetails from "./FDetails";
import FGetAccess from "./FGetAccess";
import FUpdate from "./FUpdate";
import FUpdateImages from "./FUpdateImages";
import GetBanners from "./GetBanners";
import GetCompany from "./GetCompany";
import getMvnoIntegration from "./getMvnoIntegration";
import GetSegments from "./GetSegments";
import GetSummary from "./GetSummary";
import PostBanner from "./PostBanner";
import PostExcel from "./PostExcel";
import RemoveBanners from "./RemoveBanners";
import UpdateProducts from "./UpateProducts";
import UpdateFinancial from "./UpdateFinancial";
import updateOrCreateTokenMvno from "./updateOrCreateTokenMvno";
import UpdateStatus from "./UpdateStatus";

class Service {
  details = FDetails;
  updateImages = FUpdateImages;
  getSummary = GetSummary;
  getCompany = GetCompany;
  update = FUpdate;
  getSegments = GetSegments;
  createCustomer = CreateCustomer;
  updateFinancial = UpdateFinancial;
  updateProducts = UpdateProducts;
  postExcel = PostExcel;
  updateStatus = UpdateStatus;
  access = FGetAccess;
  createAccess = FCreateAccess;
  getMvnoIntegration = getMvnoIntegration;
  updateOrCreateTokenMvno = updateOrCreateTokenMvno;
  getBanners = GetBanners;
  postBanner = PostBanner;
  removeBanners = RemoveBanners;
}

export default new Service();
