/* eslint-disable no-throw-literal */
import { AxiosResponse } from "axios";
import queryFormatter from "../../utils/queryFormatter";
import { axiosProvider } from "../../utils/axiosProvider";
import { DefaultErrorResponse } from "../../interface/DefaultErrorResponse";

const RemoveBanners = async (id: number): Promise<void> => {
  const URL = queryFormatter({ route: `dashboard/stations/banners/${id}` });
  try {
    const { data }: AxiosResponse<null> = await axiosProvider.delete(URL);
  } catch (error: any) {
    throw error.response.data as DefaultErrorResponse;
  }
};

export default RemoveBanners;
