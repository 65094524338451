import { CSSProperties } from "styled-components";
import Button from "../../buttons/Button";
import { IButtonProps } from "../../buttons/Button/interfaces/IButtonProps";
import Column from "../../containers/Column";
import GenericText from "../../texts/GenericText";
import { GrayContainer } from "./styles";
import { useRef } from "react";
import Row from "../../containers/Row";

export default function EditImage({
  title,
  image,
  buttonProps = {},
  onChange,
  width,
  buttonPropsSecondary,
  renderInsideComponents = () => <></>,
}: {
  title: string;
  image?: string;
  onChange: (Blob: Blob) => void;
  buttonProps?: IButtonProps;
  buttonPropsSecondary?: IButtonProps;
  width?: CSSProperties["width"];
  renderInsideComponents?: () => React.JSX.Element;
}) {
  const inputRef = useRef<HTMLInputElement>(null);
  return (
    <Column>
      <GenericText alignSelf="flex-start" margin="10px 0px">
        {title}
      </GenericText>
      <GrayContainer width={width}>
        <Row justifyContent="space-between">
          {buttonPropsSecondary ? (
            <Button
              title="Excluir"
              {...buttonPropsSecondary}
              width="80px"
              height="30px"
              alignSelf="flex-start"
              text={buttonPropsSecondary.text || "Remover"}
              textProps={{ font: "Regular", size: "h6" }}
              margin="0px 5px 10px 10px"
              color="red"
              onClick={(e) => {
                buttonPropsSecondary?.onClick?.(e);
              }}
            />
          ) : (
            <></>
          )}
          <Button
            title="Editar"
            {...buttonProps}
            width="80px"
            height="30px"
            alignSelf="flex-start"
            text={buttonProps.text || "Editar"}
            textProps={{ font: "Regular", size: "h6" }}
            margin="0px 5px 10px 10px"
            onClick={() => {
              inputRef?.current?.click();
            }}
          />
        </Row>

        {image ? (
          <img src={image} style={{ width: "70%" }} />
        ) : (
          <div>
            <GenericText>Não há imagem no momento</GenericText>
          </div>
        )}
        {renderInsideComponents()}
      </GrayContainer>

      <input
        type="file"
        onChange={(e) => {
          if (!e?.target?.files) return;
          onChange(e.target?.files[0]);
        }}
        style={{ display: "none" }}
        ref={inputRef}
      />
    </Column>
  );
}
